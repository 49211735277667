import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import AuthService from 'services/AuthService';
import {getCookie, removeCookie, setJwtCookie} from '../../services/CookieService'
import {AUTH_TOKEN, REFRESH_TOKEN} from "../../constants/AuthConstant";
import {saveCurrentUserData} from "../../serviceHandler/user/UserServiceHandler";
import {notification} from "antd";

export const initialState = {
    loading: false,
    message: '',
    showMessage: false,
    redirect: '',
    token: getCookie(AUTH_TOKEN) || null
}

export const signIn = createAsyncThunk('auth/login', async (data, {rejectWithValue}) => {
    const {email, password, _remember_me} = data
    try {
        const response = await AuthService.login({email, password, _remember_me})
        const token = response.token;
        if (token) {
            removeCookie(AUTH_TOKEN);
            removeCookie(REFRESH_TOKEN);
            localStorage.removeItem('userData');
            if (_remember_me) {
                setJwtCookie(response, true)
            } else {
                setJwtCookie(response, false)
            }
            await saveCurrentUserData()
        }
        return token;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})

export const signUp = createAsyncThunk('auth/register', async ({data, navigate, t, captchaValue}, {rejectWithValue}) => {
    try {
        const response = await AuthService.register(data, captchaValue)
        if (response.firstName) {
            notification.success({message: t('registration.completed')});
            navigate('/auth/login');
        }
        return response.firstName;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})

export const signOut = createAsyncThunk('auth/logout', async () => {
    removeCookie(AUTH_TOKEN);
    removeCookie(REFRESH_TOKEN);
    localStorage.removeItem('userData');
})


export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        authenticated: (state, action) => {
            state.loading = false
            state.redirect = '/'
            state.token = action.payload
        },
        showAuthMessage: (state, action) => {
            state.message = action.payload
            state.showMessage = true
            state.loading = false
        },
        hideAuthMessage: (state) => {
            state.message = ''
            state.showMessage = false
        },
        signOutSuccess: (state) => {
            state.loading = false
            state.token = null
            state.redirect = '/'
        },
        showLoading: (state) => {
            state.loading = true
        },
        signInSuccess: (state, action) => {
            state.loading = false
            state.token = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(signIn.pending, (state) => {
                state.loading = true
            })
            .addCase(signIn.fulfilled, (state, action) => {
                if (action.payload) {
                    state.loading = false
                    state.redirect = '/'
                    state.token = action.payload
                } else {
                    state.showMessage = true
                    state.loading = false
                }
            })
            .addCase(signIn.rejected, (state, action) => {
                state.message = action.payload
                state.showMessage = true
                state.loading = false
            })
            .addCase(signOut.fulfilled, (state) => {
                state.loading = false
                state.token = null
                state.redirect = '/'
            })
            .addCase(signOut.rejected, (state) => {
                state.loading = false
                state.token = null
                state.redirect = '/'
            })
            .addCase(signUp.pending, (state) => {
                state.loading = true
            })
            .addCase(signUp.fulfilled, (state, action) => {
                state.loading = false
                state.redirect = '/'
                state.token = null
            })
            .addCase(signUp.rejected, (state, action) => {
                state.message = action.payload
                state.showMessage = true
                state.loading = false
            })
    },
})

export const {
    authenticated,
    showAuthMessage,
    hideAuthMessage,
    signOutSuccess,
    showLoading,
    signInSuccess
} = authSlice.actions

export default authSlice.reducer
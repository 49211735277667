import {Spin} from "antd";
import React from "react";

const LoadingComponent = () => {
    return (
        <div>
            <Spin style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '60vh'}}
                  size="large"/>
        </div>
    )
};

export default LoadingComponent;

import Cookies from 'universal-cookie';
import { AUTH_TOKEN, REFRESH_TOKEN, REMEMBER_ME } from "../constants/AuthConstant";

function setCookie(name, value, expires) {
    const cookies = new Cookies();
    cookies.set(name, value, { path: '/', expires });
}

function setJwtCookie(response, rememberMe = false) {
    const expires = new Date();
    if (rememberMe) {
        expires.setDate(expires.getDate() + 30);
        setCookie(REMEMBER_ME, true, expires);
    } else {
        expires.setMinutes(expires.getMinutes() + 60);
        setCookie(REMEMBER_ME, false, expires);
    }

    setCookie(AUTH_TOKEN, response.token, expires);

    const expiresRefresh = new Date();
    expiresRefresh.setMonth(expiresRefresh.getMonth() + 12);
    setCookie(REFRESH_TOKEN, response.refresh_token, expiresRefresh);
}

function setUser(data) {
    localStorage.setItem('userData', JSON.stringify(data));
}

function getUser() {
    const userData = localStorage.getItem('userData');
    return JSON.parse(userData);
}

function getCookie(name) {
    const cookies = new Cookies();
    return cookies.get(name);
}

function removeCookie(name) {
    const cookies = new Cookies();
    cookies.remove(name, { path: '/' });
}

export {
    setCookie,
    getCookie,
    setUser,
    getUser,
    removeCookie,
    setJwtCookie
};
